import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppContextService, AppInitializer, BroadcastService, SettingsService } from '@primavera/services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    appReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    readonly className = 'is-app-initializing';

    constructor(private titleService: Title,
        private settingsService: SettingsService,
        private appContextService: AppContextService,
        private broadcastService: BroadcastService,
        private appInitializer: AppInitializer) {
        this.broadcastService.subscribe('clientAppReady', () => {
            if (document.getElementsByClassName('main-loader')?.length > 0) {
                document.body.classList.remove(this.className);

                document.getElementsByClassName('main-loader')[0].remove();
            }

            this.appReady$.next(true);
        });

        this.appInitializer.ready$.subscribe(async value => {
            if (value) {
                await this.init();
            }
        });
    }

    private async init() {
        try {
            document.body.classList.add(this.className);

            await this.appInitializer.initialize();

            this.loading$.next(false);

            const context = this.appContextService.getContext();

            let productName = context.productName;
            if (!productName) {
                productName = this.settingsService.environmentSettings.productName;
            }

            this.titleService.setTitle(productName);
        } catch {
            this.loading$.next(false);
        }
    }
}
