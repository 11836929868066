import { Injectable, inject } from '@angular/core';
import { EnvironmentSettings, WildcardService } from '@primavera/services';
import { Observable, Subscriber, firstValueFrom } from 'rxjs';

@Injectable()
export class WildcardCustomService extends WildcardService {
	private environmentSettings = inject(EnvironmentSettings);

	override formatOperation(operation: string): Observable<string> {
		operation = operation.replace('{apiVisEndpoint}', (this.environmentSettings as any).apiVisEndpoint);
		return super.formatOperation(operation);
	}
}
