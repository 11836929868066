import { Provider } from '@angular/core';
import { WildcardService, AppInitializerCustomService } from '@primavera/services';
import { WildcardCustomService } from './services/wildcard-custom.service';
import { CustomInitService } from './services/custom-init.service';

export const customServices: Array<Provider> = [];
export const customMainServices: Array<Provider> = [
    {
        provide: WildcardService,
        useClass: WildcardCustomService
    },
    /*{
        provide: AppInitializerCustomService,
        useClass: CustomInitService
    }*/
];
