import { Routes } from '@angular/router';
import { ModalRouterInjectorComponent, PanelRouterInjectorComponent } from '@primavera/components/features/routing';
import { AmsGuard, IdentityGuard } from '@primavera/services';

export const mainRoutes: Routes = [
  {
    path: ':account/:subscription',
    canActivate: [IdentityGuard, AmsGuard],
    loadChildren: () => import('./app.module').then(m => m.AppModule),
  },
  {
    path: 'popup',
    outlet: 'popup',
    component: ModalRouterInjectorComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./app.module').then(m => m.AppModule),
      }
    ]
  },
  {
    path: 'panel',
    outlet: 'panel',
    component: PanelRouterInjectorComponent,

    children: [
      {
        path: '',
        loadChildren: () => import('./app.module').then(m => m.AppModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

export const customRoutes: any = ['/start'];

