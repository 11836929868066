import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, RouterModule, UrlHandlingStrategy, UrlSerializer, UrlTree } from '@angular/router';
import { AppInitializer, EnvironmentSettings, PrimaveraServicesModule } from '@primavera/services';
import { ShellModule } from '@primavera/shell';
import { customMainServices } from '../../custom-code/custom.services';
import { AppComponent } from './app.component';
import { mainRoutes } from './main.routes';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    override parse(url: string): UrlTree {
        return super.parse(url.toLowerCase());
    }
}

export class HybridUrlHandlingStrategy implements UrlHandlingStrategy {
    shouldProcessUrl(url: UrlTree) {
        return true;
    }
    extract(url: UrlTree) {
        return url;
    }
    merge(url: UrlTree, whole: UrlTree) {
        const urlTree = url as UrlTree;

        // fix camel case when using router.navigate

        // eslint-disable-next-line @typescript-eslint/dot-notation
        urlTree.root.children['primary']?.segments.forEach(seg => {
            seg.path = seg.path.toLowerCase();
        });

        return urlTree;
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        PrimaveraServicesModule,
        ShellModule,
        RouterModule.forRoot(
            mainRoutes,
            {
                enableTracing: false
            }
        )
    ],
    exports:
        [
            AppComponent
        ],
    providers: [
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        },
        {
            provide: UrlHandlingStrategy,
            useClass: HybridUrlHandlingStrategy
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializer: AppInitializer) => async () => await appInitializer.init(),
            deps: [AppInitializer],
            multi: true
        },
        ...customMainServices
    ],
    bootstrap: [AppComponent]
})
export class MainModule {
    constructor(envSettings: EnvironmentSettings) { }
}
