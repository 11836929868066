import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DEPLOY_URL_PROVIDER, EnvironmentSettings } from '@primavera/services';
import 'zone.js/dist/zone';
import { MainModule } from './app/main.module';

const deploy = DEPLOY_URL_PROVIDER;

fetch(deploy.useValue + 'assets/environment.json')
  .then((response) => response.json())
  .then((config: EnvironmentSettings) => {

    if (config.production) {
      enableProdMode();
    }

    const bootstrapModule = MainModule;

    platformBrowserDynamic([
      {
        provide: EnvironmentSettings,
        useValue: config
      },
      DEPLOY_URL_PROVIDER
    ])
      .bootstrapModule(bootstrapModule).catch((err) => console.error(err));
  });
